@import 'base';
$minWidth:768px;
$mobilewidth:500px;
$blueLink: #23a5b9;
$purple: #ef4050;
.styled-date-holder {
  background: #fff;
  border: 1px solid #bcc7c4;
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0;
  align-items: center;
  .departure-date &,.checkin-column &{      
    body:not(.ltr) &{
      //background-image: url("../image/left-date.svg");
      border-radius: 0 4px 4px 0;
    }  
    body.ltr &{  
//      background-image: url("../image/right-date.svg");
      border-radius: 4px 0 0 4px;
    } 
    .multy-dest-route &{
      body.body &{
        border-radius: 4px;
      }  
    }      
  }
  .return-date &,.checkout-column &{
    body:not(.ltr) &{
      //background-image: url("../image/right-date.svg");
      border-radius: 4px 0 0 4px;
    }  
    body.ltr &{  
     // background-image: url("../image/left-date.svg");
      border-radius: 0 4px 4px 0;
    }  
  }
  .ant-calendar-picker .anticon{
    display: none;
  }
  background-size: 22px;
  background-repeat: no-repeat;
  body:not(.ltr) &{
    background-position: right 7px top 10px;  
  }  
  body.ltr &{  
    background-position: left 7px top 10px;  
  } 
  .PDatePicker-input,.ant-calendar-picker-input {
    position: absolute;
    opacity: 0;
    right: 0;
    top: 0;
    cursor: pointer;
  }
  .styled-date {
    font-size: 12px;
    padding: 0 10px;
    &.styled-date-date {
      font-size: 40px;
      line-height: 25px;
      margin-top: 5px;
    }
  }
}
.PDatePicker-input,.ant-calendar-picker-input{  
  .body .search-box-holder .search-route-item &{
    padding:10px;
    text-align:center;
    @media screen and (min-width: $minWidth){

    }
  }
}


.PDatePicker-holder {
  .PDatePicker-input{
    border: 1px solid #bcc7c4;
    border-radius: 3px;
    padding: 0 10px;
    outline: none;
    padding-left: 35px;
    .body .flight-date-item &{
      @media screen and (min-width: $minWidth){
        padding:10px;
        text-align:center;
      }
    }


    background-size: 22px;
    background-repeat: no-repeat;
    background-position: right 7px top 10px;    
  }  
  & > div {
    position: absolute;
    left: 0;
    top: 100%;
    width:100%;
    max-width: 280px;
    @media screen and (max-width: $minWidth){
      max-width: 100%;
    }
    min-width: 280px;
    background: #fff;
    z-index: 3;
    margin-top: 5px;
    border-radius: 4px; 
    box-shadow: 0 0 4px grey; 
    padding-bottom: 25px;
    @media screen and (max-width: $minWidth){
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      border-radius: 0;
      &::before{
        display: none;
      }
    }
    .calendarContainer{
      box-shadow: none;
      width: 278px;
      @media screen and (max-width: $mobilewidth){
        width: 100%;
      }
      .heading{
        font-weight: 700;
        border-bottom: 1px solid #d9d9d9;
        margin: -10px -15px 13px;
        padding: 0 10px 7px;
        .prev, .next {
          min-width: 0;
          padding: 0;
          padding: 0;
          height: 30px;
          width: 30px;
          border-radius: 0;
          line-height: 35px; 
          outline: none;       
        }
        .title {
          font-size: 14px ;
          padding: 5px 6px 2px;
          min-width: 0;
          border: 0 none ;
          outline: none ;
          background: transparent ;
          font-weight: bold;
        }      
      } 
      .monthsList {
        display: flex;
        flex-wrap: wrap;
        & > div{
          flex:0 0 33.33%;
          button{
            font-size: 13px;
            width: 100%;
            min-width: 0;
            border-radius: 0;
            height: auto;
          }
        } 
      } 
      .selectToday {
        display: none;
      }
    }
  
    .dayWrapper button {
      min-width: 0;
    } 
    
    .PDatePicker-buttons {
      padding: 10px 15px;
      border-bottom: 1px solid #ddd;    
      .button{
        height: 30px;
        line-height: 15px;
        font-size: 13px;
      }
    }
    .calendarContainer .dayWrapper {
      width: 14.28571429%;
      height: 24px;
      padding: 0 6px;
      margin: 4px 0;
      &.disabled{
        background-color: #f5f5f5;
      }
      button {
        min-width: 0;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        font-size: 13px;
        line-height: 25px;
        border: 1px solid transparent;
        background-color: #fff;
        color: rgba(0, 0, 0, 0.65);
        cursor: pointer;
        &[disabled]{
          border-color: transparent !important;
          background-color: transparent;
          color:rgba(0, 0, 0, 0.25) ;
          cursor: default;
        }
      } 
      &.currentMonth{
        button {
          background-color: #fff;
          color: #555;
          &[disabled]{
            background-color: transparent;
            color:#ddd ;
          } 
          &:hover{
            background-color: #e6f7ff;
          } 
          &:active{
            color: #fff;
            background-color: #40a9ff;
          }                   
        } 
      } 
      &.selected:not(.disabled), &.selected.currentMonth:not(.disabled){
        button{
          background-color: #bae7ff;
          border-color: #bae7ff !important;
        }
      }
      // &.today button{
      //   &,&[disabled]{
      //     background-color: $purple;
      //     color: #fff;
      //   }
      // } 
    }
    .daysOfWeek{
      & > div {
        width:30px;
      }
    }
  
  }
}
.PDatePicker-calnedar{
  &.persian{
    font-family: 'vazir';
  }
  &.english{
    font-family: Arial;
  }

}

.PDatePicker-holder input.PDatePicker-input {
  padding: 0 10px !important;
  height: 32px;
  width: 100%;
  border-color: #d9d9d9;
  .red-border &{
    border-color:#f5222d;
  }
}
.PDatePicker-close{
  display: none;
}