@import '~antd/dist/antd.css';
@import 'base';
@import 'login';
@import 'overideAntDesign';
@import './icon.scss';
body {
  font-family: 'Open Sans', 'sans-serif';
  font-weight: normal;
}

//general
.icon_input {
  color: #777;
}
//loading
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 99999;
  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 85px;
  }
}

//navbar

.hover-efect-icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  height: 60px;
  width: 20px;
  .itours-icon {
    display: block;
    position: absolute;
    margin-top: -12px;
    left: 0;
    transition: all 0.3s ease;
    &:first-child {
      top: 50%;
      .hover-efect:hover &,
      .ant-menu-submenu-open & {
        top: -100%;
      }
    }
    &:last-child {
      top: 150%;
      .hover-efect:hover &,
      .ant-menu-submenu-open & {
        top: 50%;
      }
    }
  }
}
.main-menu-user {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  float: right;
  padding: 0 10px;
  border-right: 1px solid #ccc;
  color: #555;
  &:hover {
    background-color: #f1f1f1;
  }
}
.ant-menu-submenu .ant-menu-submenu-title {
  margin: 0;
  padding: 0 10px;
  background-color: transparent;
  a {
    color: #fff !important;
  }
}
.ant-menu-submenu ul.ant-menu .ant-menu-item a,
.ant-menu-item .link {
  padding: 0 10px;
  color: #555;
  &:hover {
    background-color: #f1f1f1;
  }
}
.user-menu-item {
  display: block;
  padding: 10px;
  border-bottom: 1px solid #eee;
  color: inherit;
}

.name-avatar {
  color: #fff;
  background: #2c9bc8;
  width: 35px;
  height: 35px;
  display: block;
  line-height: 35px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 50%;
  font-size: 16px;
}
.ant-menu-submenu .ant-menu.ant-menu-sub {
  background: #333;
  color: #fff;
  li.ant-menu-item {
    background-color: transparent;
    a {
      background-color: transparent;
      color: inherit;
      color: #fff;
      &:hover {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }
  }
}
.ant-menu-submenu-popup {
  @media screen and (max-width: 850px) {
    .ant-menu-submenu {
      position: relative;
      .ant-menu-submenu-title {
        padding: 0 10px;
        margin: 0;
        .ant-menu-submenu-arrow {
          &:after,
          &:before {
            background: #fff;
          }
        }
        & + div {
          top: 100% !important;
          .ant-menu-submenu {
            top: 0 !important;
            border-radius: 0;
            .ant-menu-sub {
              border-radius: 0;
            }
            a {
              padding-left: 30px;
            }
          }
        }
      }
    }
    a {
      color: #eee !important;
      background-color: transparent !important;
      .itours-icon {
        display: none;
      }
    }
  }
}
.main-header {
  background: #333333;
  color: #fff;
  box-shadow: 0 0 2px gray;
  .container .main-menu .ant-menu-submenu-title > .menu-main-item {
    padding: 0;
  }
  .currency-select-holder,
  .profile-link-holder {
    float: right;
  }
  .main-menu {
    display: block;
    float: left;
    ul.ant-menu > li.ant-menu-item > a {
      @media screen and (max-width: 1099px) {
        padding-left: 0;
        padding-right: 0;
        background-color: transparent;
        margin-right: 20px;
      }
    }
  }
  @media screen and (min-width: 1100px) {
    .currency-select-holder {
      margin-top: 14px;
    }
  }
  @media screen and (max-width: 1099px) {
    .currency-select-holder {
      position: absolute;
      top: 15px;
      right: 15px;
      float: none;
    }
  }
  .company-name {
    max-width: 160px;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .companyInfo {
    @media screen and (min-width: 1100px) {
      float: left;
      padding-right: 15px;
    }
    a {
      font-size: 18px;
      color: inherit;
      display: block;
    }
  }
  .container {
    .ant-menu-horizontal > li {
      .ant-menu-submenu-title::after {
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-left: 10px;
        border-right: 2px solid #bbb;
        border-bottom: 2px solid #bbb;
        transform: rotate(45deg);
        position: relative;
        top: -2px;
      }
      padding: 0;
      border-bottom: 0 none;
      top: 0;
      & > a,
      & > .ant-menu-submenu-title,
      & > .link {
        padding: 0 7px;
        color: #fff;
        display: inline-block;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover {
          background-color: rgba(0, 0, 0, 0.6);
        }
      }
      .menu-main-item {
        padding: 0 10px;
        color: #fff;
        display: inline-block;
      }
    }
    .ant-menu-horizontal {
      border-bottom: 0 none;
    }
    .logo {
      text-align: center;
      img {
        padding: 5px;
        height: 60px;
      }
    }
    .ant-menu {
      background-color: transparent;
    }
    .account {
      text-align: center;
      .ant-badge {
        margin-top: 16px;
        cursor: pointer;
        .ant-badge-count {
          top: 2px;
        }
      }
    }
    .currencyLink {
      margin-top: 16px;
    }
  }
}
//Account Popover
.account-popover .ant-menu-vertical {
  border-right: 0 none;
  .ant-menu-item {
    margin: 0 !important;
    border-bottom: 1px solid #eaeaea;
    padding: 0;
    & > a,
    .no-link-item {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
.ant-popover-inner-content {
  padding: 0;
  .accountPopover {
    .accountName {
      padding: 14px 16px;
      font-size: 16px;
    }
    .link {
      padding: 8px 16px;
      cursor: pointer;
      transition: all 0.2s;
      a {
        color: gray;
        display: block;
        width: 100%;
        &:hover {
          color: white;
        }
      }
      &:hover {
        a {
          color: white;
        }
        background-color: gray;
        color: white;
      }
    }
  }
}
//home
.recent-searches-holder a {
  color: inherit;
}
.recent-search {
  &.type-flight {
    background-image: url('../Images/design/flight.jpg');
  }
  &.type-hotel {
    background-image: url('../Images/design/hotel.jpg');
  }
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  min-height: 130px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  transition: all 0.15s linear;
  outline: none !important;
  text-align: left;
  padding: 10px;
  border-radius: 3px;
  &:hover {
    border-color: #aaa;
    background-image: url('../Images/design/white.jpg');
  }
}
.ant-card.light-padding .ant-card-body {
  padding: 15px;
}
.card-container {
  @media screen and(min-width:576px) {
    background: #f5f5f5;
    padding: 20px;
    border: 1px solid #ebedf0;
    border-radius: 2px;
  }
  .ant-tabs-content {
    background-color: #fff;
    border: 1px solid #ebedf0;
    border-top: 0 none;
  }
  .ant-tabs-bar {
    margin-bottom: 0;
  }
}

.form-group {
  margin-bottom: 15px;
}
.green {
  color: green;
}
.font-10 {
  font-size: 10px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.font-18 {
  font-size: 18px;
}
.font-22 {
  font-size: 22px;
}
.container {
  max-width: 1300px;
  margin: auto;
  padding: 0 20px;
  width: 100%;
  @media screen and (max-width: 991px) {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.sortContainer {
  .paginationTop {
    text-align: right;
  }
  @media screen and (max-width: 767px) {
    .ant-radio-button-wrapper {
      display: block;
      margin-top: 10px;
      text-align: center;
    }
    .ant-radio-group {
      width: 100%;
    }
  }
}
.filterholder {
  @media screen and (max-width: 767px) {
    border-radius: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: none;
    z-index: 100;
    background: #fff;
    &.visible {
      display: block;
    }
  }
}
.filterholder .filter-close-btn {
  position: fixed;
  bottom: 20px;
  right: 50%;
  background-color: $red;
  color: #fff;
  border: 0 none;
  width: 100px;
  padding: 5px 0;
  margin-right: -50px;
  outline: 0 none;
}
.filter-btn {
  position: fixed;
  bottom: 15px;
  z-index: 10;
  color: #fff;
  border: 0 none;
  width: 100px;
  padding: 5px 0;
  right: 50%;
  margin-right: -50px;
  outline: 0 none;
}
.filter {
  border-radius: 4px;

  .checkbox-holder {
    margin-bottom: 5px;
  }
  .rating-filter label.custom-checkbox {
    display: flex;
    align-items: center;
  }
}
.filter-item:not(:last-child) {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.clearfix {
  &::before,
  &::after {
    display: table;
    clear: both;
    content: '';
  }
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.text-justify {
  text-align: justify;
}
.text-right {
  text-align: right;
}
.pull-right-md {
  @media (min-width: 768px) {
    float: right;
  }
}
.text-right-lg {
  @media (min-width: 992px) {
    text-align: right;
  }
}
.text-left {
  text-align: left;
}
.margin-bottom {
  margin-bottom: 15px;
}
.margin-top {
  margin-top: 15px;
}
.margin-right {
  margin-right: 15px;
}
.margin-left {
  margin-left: 15px;
}
.margin-right-half {
  margin-right: 8px;
}
.margin-left-half {
  margin-left: 8px;
}
.margin-bottom-light {
  margin-bottom: 5px;
}
.margin-bottom-half {
  margin-bottom: 10px;
}
.margin-top-light {
  margin-top: 5px;
}
.margin-top-10 {
  margin-top: 10px;
}
.no-border-top {
  border-top: 0 none !important;
}
.full-width {
  width: 100%;
}
.half-width {
  width: 50%;
}
.max-width-200 {
  max-width: 200px;
}
.max-width-700 {
  max-width: 700px;
  margin: auto;
}
.relative {
  position: relative;
}
.static {
  position: static;
}
.border-top {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}
.bold {
  font-weight: bold;
}
.black {
  color: #111;
}
.blue {
  color: $blue;
}
.red {
  color: $red;
}
.orange {
  color: $orange;
}
.green {
  color: $green;
}
.grey {
  color: $grey;
}
.dark-blue {
  color: $darkblue;
}
.button {
  padding: 8px 15px;
  line-height: 22px;
  text-align: center;
  display: inline-block;
  min-width: 120px;
  border-radius: 4px;
  border: 0 none;
  outline: none !important;
  height: auto;
  cursor: pointer;
  &.small-btn {
    padding: 3px 7px;
    min-width: 0;
  }
  &.blue-btn {
    background-color: $blue;
    color: white;
    transition: all 0.3s;
    &:hover {
      background-color: $blueHover;
    }
  }
  &.green-btn {
    background-color: #47cf73;
    color: white;
    transition: all 0.3s;
    &:hover {
      background-color: #36b760;
    }
  }
  &.gray-btn {
    background-color: #ddd;
    color: #555;
    transition: all 0.3s;
    min-width: 0;
    padding-left: 5px;
    padding-right: 5px;
    font-weight: bold;
    border-radius: 2px;
    &:hover {
      background-color: #eee;
    }
  }
  &.red-btn {
    background-color: rgb(239, 64, 80);
    color: white;
  }
  &.white-btn {
    background-color: white;
    color: #777;
    transition: all 0.3s;
    border: 1px solid $grayMenu;
    &:hover {
      border: 1px solid $blue;
      color: $blue;
    }
    //supplier Color
    &.supplier-color {
      padding-right: 25px;
      overflow: hidden;
      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 7px;
        height: 100%;
      }
      //TotalStay
      &._001::before,
      &._018::before {
        background-color: black;
      }

      //Sabre
      &._002::before {
        background-color: #e40000;
      }

      //HotelBeds
      &._003::before {
        background-color: #5691ff;
      }

      //Stuba
      &._004::before {
        background-color: green;
      }

      //Pegasus
      &._005::before {
        background-color: #ffeb3b;
      }

      //Restel
      &._006::before {
        background-color: #d64cd2;
      }

      //HotelsPro
      &._007::before {
        background-color: #f9a11b;
      }

      //Amadeus
      &._008::before {
        background-color: #ccc;
      }

      //TravelFusion
      &._009::before {
        background-color: #ace8ea;
      }

      //AmadeusThr
      &._010::before {
        background-color: #a94b29;
      }

      //Mahan
      &._011::before {
        background-color: #ffc8db;
      }

      //Farelogix
      &._012::before {
        background-color: #d1e65b;
      }

      //Dotw
      &._013::before {
        background-color: #1ebab4;
      }

      //Hubfares
      &._016::before {
        background-color: #3f51b5;
      }

      //IRTech
      &._015::before {
        background-color: green;
      }

      //chartex
      &._019::before {
        background-color: #f9a11b;
      }

      //sabreSWE
      &._021::before{
        background-color: #000000;
      }

    }
  }
}


//  .suplier.hint {
//   padding: 25px 50px;
//   > .button{
//     width: 20%;
//     margin: 10px;
//   }
//   h2{
//     padding: 10px;
//   }
//  }
//  <div className="suplier hint">
//  <h2>Flight</h2>
//  <button className="button white-btn relative supplier-color _002">Sabre</button>
//  <button className="button white-btn relative supplier-color _005">Pegasus</button>
//  <button className="button white-btn relative supplier-color _008">Amadeus</button>
//  <button className="button white-btn relative supplier-color _010">AmadeusThr</button>
//  <button className="button white-btn relative supplier-color _009">TravelFusion</button>
//  <button className="button white-btn relative supplier-color _011">Mahan</button>
//  <button className="button white-btn relative supplier-color _012">Farelogix</button>
//  <button className="button white-btn relative supplier-color _016">Hubfares</button>
//  <button className="button white-btn relative supplier-color _015">IRTech</button>
//  <button className="button white-btn relative supplier-color _019">chartex</button>
//  <button className="button white-btn relative supplier-color _021">sabreSWE</button>
// </div>
// <div className="suplier hint">
//  <h2>Hotel</h2>
//  <button className="button white-btn relative supplier-color _001">TotalStay</button>
//  <button className="button white-btn relative supplier-color _003">HotelBeds</button>
//  <button className="button white-btn relative supplier-color _004">Stuba</button>
//  <button className="button white-btn relative supplier-color _006">Restel</button>
//  <button className="button white-btn relative supplier-color _007">HotelsPro</button>
//  <button className="button white-btn relative supplier-color _013">Dotw</button>
//  <button className="button white-btn relative supplier-color _018">sunhotel</button>
// </div>
// <br/><br/><br/><br/>

.content-full-height {
  height: calc(100vh - 160px);
}
.card-padding {
  padding: 15px;
}
.bg-white {
  background-color: #fff;
}

.link-style-btn {
  background-color: transparent;
  border: 0 none;
  padding: 0;
  font-family: inherit;
  min-width: 0;
  border-radius: 0;
  color: $blue;
  outline: none !important;
  cursor: pointer;
  width: auto !important;
  &:hover {
    color: $blueHover;
    background-color: transparent;
  }
}

.box-border {
  border: 1px solid #ddd;
}
.no-border-bottom {
  border-bottom: 0 none;
}
.PageContainer {
  position: relative;
  text-align: right;
}

.pagination-btn,
.pagination-nav {
  background-color: #fff;
  border: 1px solid #ddd;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  outline: none !important;
  cursor: pointer;
  &.clicked {
    background-color: $blue;
    color: #fff;
  }
}
.hide {
  display: none;
}
.ant-form-item {
  margin-bottom: 15px;
  .confirmButton & {
    margin-bottom: 0;
  }
}
.text-center {
  text-align: center;
}
@keyframes progress {
  0% {
    transform: scaleX(0);
    visibility: visible;
  }
  //50%{
  //  transform: scaleX(1);
  //}
  100% {
    transform: scaleX(1);
    visibility: hidden;
  }
}
.search-section {
  padding-top: 15px;
  span.ant-calendar-picker,
  .ant-form-item-children {
    display: block;
  }
  .loading-bar {
    background-color: $blue;
    transform-origin: left;
    transform: scaleX(0);
    height: 2px;
    transition: all 0.5s ease-in;
    &.progress {
      animation: progress 2s;
      animation-iteration-count: 1;
    }
  }
  .ethnic-form-control {
    @media screen and (min-width: 576px) {
      padding-top: 33px;
    }
  }
}
.bg-grey {
  background: #e0e0e0;
}

//hotel results
.hotel-results-number {
  @media screen and (max-width: 600px) {
    display: block;
  }
}
.listContainer .ant-empty {
  background: #fff;
  padding: 50px 0;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin: 0;
}
.ant-empty-description {
  margin: 30px 0 45px;
}

//layout:
section.ant-layout {
  min-height: 100vh;
  background-color: #fff;
}

.addressFeild {
  color: #bbb;
}

.pair-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  &.wrap {
    flex-wrap: wrap;
  }
}
.break-word {
  word-break: break-all;
}
.margin-right-light {
  margin-right: 5px;
}
.margin-left-light {
  margin-left: 5px;
}
.rateContainer {
  width: auto;
}
.ant-tag {
  margin-bottom: 8px;
  &.no-margin {
    margin: 3px 0 0;
  }
}

//loading styles:
.loading-section {
  padding: 50px 20px;
  img {
    width: 50px;
    margin-bottom: 20px;
    display: inline-block;
  }
}
.loading-full-page {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  z-index: 1000;
}

.logo-loading {
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background-image: url('../Images/design/loading.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-animation: rotation 0.9s linear infinite;
    animation: rotation 0.9s linear infinite;
  }
  img {
    margin-bottom: 0;
    position: absolute;
    left: 50%;
    margin-left: -25px;
    top: 50%;
    margin-top: -25px;
  }
}
@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

//footer
.footer {
  background-color: #333;
  color: #fff;
}

.bullet-list {
  padding: 0;
  list-style-position: inside;
  margin: 0;
  & > li {
    list-style-type: none;
    padding-left: 15px;
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      background-color: $red;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      top: 5px;
      left: 0;
      position: absolute;
    }
  }
}

.min-height-400 {
  min-height: 400px;
}
.min-height-150 {
  min-height: 150px;
}

.ant-layout .profile-side-menu {
  .ant-menu-item {
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #ddd;
    a {
      padding-right: 15px;
      padding-left: 15px;
      border-right: 0 none;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
  .ant-menu {
    background-color: transparent;
  }
}
.align-with-labeled {
  padding-top: 20px;
}
.vr-padding {
  padding-top: 15px;
  padding-bottom: 15px;
  @media screen and (max-width: 991px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.large-vr-padding {
  padding-top: 50px;
  padding-bottom: 50px;
}
.hr-padding {
  padding-right: 15px;
  padding-left: 15px;
  @media screen and (max-width: 991px) {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.equal-height-sides > .ant-col {
  display: flex;
  & > div {
    flex: 1 1;
  }
}
.ant-menu-submenu .ant-menu {
  margin-top: -5px;
  .ant-menu-item {
    padding: 0;
    margin: 0;
    a {
      padding: 0 15px;
      &:hover {
        background-color: #e2e2e2;
      }
    }
  }
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected a {
  background-color: #f1f1f1;
}
.no-padding-content {
  .ant-card-body {
    padding: 0;
  }
}
.ant-card-body {
  @media screen and (max-width: 991px) {
    padding: 10px;
  }
}

//hotel-details:
.hotel-head-info {
  .price-block {
    flex: 0 0 250px;
    .button {
      line-height: 30px;
      padding: 0 15px;
    }
  }
  .price {
    font-size: 28px;
    line-height: 1;
    white-space: nowrap;
    font-weight: 600;
    display: block;
  }
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 850px) {
    display: block;
    padding: 0;
    > .text-right {
      text-align: left;
      margin-top: 15px;
      .price {
        float: right;
      }
    }
    .price {
      font-size: 22px;
    }
  }
  .hotel-title {
    font-size: 28px;
    font-weight: 600;
    line-height: 1;
    color: inherit;
    margin-bottom: 10px;
    @media screen and (max-width: 850px) {
      font-size: 22px;
    }
  }
  .hotel-address {
    font-size: 17px;
    margin-bottom: 5px;
    @media screen and (max-width: 850px) {
      font-size: 14px;
    }
  }
}
.align-labels {
  margin-top: 20px;
  @media screen and (min-width: 992px) {
    .home-search-wrapper .flight-search-section & {
      margin-bottom: 15px;
    }
    .home-search-wrapper & {
      margin-top: 0;
    }
  }
  .hotel-search-section & {
    @media screen and (max-width: 575px) {
      margin-top: 0;
    }
  }
}
.home-search-wrapper .ant-tabs {
  overflow: visible;
}
.home-right-section {
  .ant-table {
    overflow-x: auto;
  }
  @media screen and (max-width: 575px) {
    .ant-tabs-extra-content {
      float: none !important;
    }
  }
}
.align-labels-sm {
  @media screen and (min-width: 567px) {
    margin-top: 20px;
  }
  .home-search-wrapper .ticketing-form & {
    @media screen and (min-width: 992px) {
      margin-top: 0;
    }
  }
}
//flight search results
.route-item {
  display: flex;
  justify-content: space-between;
}
.router {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  margin-right: 10px;
  align-items: center;
  .stop-info {
    line-height: 5px;
    font-size: 12px;
    margin: 0 15px;
    .duration,
    .stop {
      padding-right: 20px;
    }
  }
}
.stop-info {
  font-size: 15px;
  font-weight: 600;
  &.change-airport {
    color: $red;
  }
  &.change-terminal {
    color: $yellow;
  }
}
.result-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 10px;
  .item-main {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .content {
      width: 100%;
    }
    .detail-btn-holder {
      margin-right: 10px;
      flex: 1 1 150px;
    }
  }
  .item-footer {
    flex: 0 0 150px;
    align-self: flex-end;
    text-align: right;
  }
}
.rule-title {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 17px;
}
.fareContainer {
  padding: 20px 0 40px;
  .totalPrice {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
  }
}
.air-line-info {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 13px;
  flex: 1 1 300px;
  .airline-logo-holder {
    margin-right: 5px;
  }
}
.included-baggage-mark {
  font-size: 12px;
  border: 1px solid;
  border-radius: 10px;
  display: inline-block;
  padding: 2px 5px;
  line-height: 12px;
  color: green;
  .anticon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 3px;
    & + span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}
.dashed-line {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    border-bottom: 1px dashed #bbb;
    width: 100%;
  }
  img {
    width: 21px;
    margin-left: 5px;
  }
}
//flight details:
.detail-flight {
  img.airline {
    width: 50px;
    margin-top: 4px;
    .body & {
      margin-right: 10px;
    }
  }
  .direction-arrow {
    margin: 0 5px;
    background-position: -576px -127px;
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
  }
  .detail-flight-item:not(:first-child) {
    @media (max-width: 850px) {
      margin-top: 20px;
    }
  }

  .route-header {
    border-bottom: 1px solid #e4e8ed;
    font-size: 16px;
    padding-bottom: 15px;
    b {
      font-size: 16px;
    }
  }
  .detail-route-item {
    line-height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    padding-bottom: 25px;
    padding-top: 20px;
    margin-left: -5px;
    margin-right: -5px;
    & > div {
      flex: 1 1;
      padding-left: 5px;
      padding-right: 5px;
    }
    .duration-info {
      flex: 0 0 70px;
      flex: 0 0 175px;
      text-align: right;
    }
    .airline-info {
      display: flex;
      align-items: center;
    }
    .semi-bold {
      font-weight: 600;
    }
  }
  .stop-info {
    background-color: #fff5e3;
    padding: 10px 15px;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
  }
  .card-title {
    padding: 8px 15px;
    background-color: #002142;
    display: block;
    color: #fff;
  }
}

// Styleguide Components.Checkbox
.radio-item {
  position: relative;
  .inline-radio & {
    display: inline-block;
    margin-right: 20px;
    @media screen and (max-width: 500px) {
      margin-right: 10px;
    }
  }
  .custom-radio {
    position: absolute;
    top: 0;
    margin: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;

    &:checked {
      & + .radio-label .radio-mark {
        border-color: #02647a;
        &::before {
          background-color: #02647a;
        }
      }
    }
  }

  .radio-label {
    line-height: 30px;
    font-weight: normal;
    .radio-mark {
      border: 2px solid #ddd;
      border-radius: 50%;
      position: relative;
      width: 16px;
      height: 16px;
      display: inline-block;
      margin-right: 5px;
      vertical-align: middle;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background: transparent;
        right: 2px;
        left: 2px;
        top: 2px;
        bottom: 2px;
        border-radius: 50%;
      }
    }
  }
}
.payment-radio {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}
.gateway-item {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ddd;
  padding: 15px;
  &:not(:last-child) {
    border-bottom: 0 none;
  }
}
.resurs-toggle-btn {
  position: absolute;
  top: 15px;
  right: 15px;
}
.card-content {
  padding: 15px;
  border: 1px solid #ddd;
}
.resurs-form button.ant-btn {
  @media (min-width: 992px) {
    margin-top: 20px;
  }
}
.paymentDetails strong {
  margin-bottom: 10px;
}
.flight-detail-footer {
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.totalPrice {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 10px;
}
.flex-ends {
  display: flex;
  justify-content: space-between;
}
.baggage-details-holder {
  display: table;
  @media (max-width: 768px) {
    display: block;
  }
}
.baggegesInformation {
  display: table-row;
  padding: 15px 0;
  & > div {
    display: table-cell;
    padding: 5px 10px;
    vertical-align: middle;
  }
  .airplaneIcon {
    text-align: center;
    //margin: 0 20px;
  }
  .origin {
    text-align: center;
  }
  .destination {
    text-align: center;
  }
  @media (max-width: 768px) {
    display: block;
    text-align: center;
    & > div {
      display: inline-block;
      padding: 5px;
      &.baggages-list {
        display: block;
        margin-left: 0;
      }
    }
  }
}
.airplaneIcon img {
  width: 21px;
}

//flight itinery detail
.itinerary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.itinery-details .itinery-item {
  border-bottom: 1px solid #ccc;
}
//flight results
.has-airport-change {
  color: $red;
  font-weight: bold;
}

.flight-route-item,
.flight-results-label {
  @media screen and (min-width: 992px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      padding-right: 5px;
    }
    flex: 0 0 25%;
    display: flex;
    flex-wrap: nowrap;
  }
  .stops-col {
    flex: 0 0 12%;
  }
  .airline-col {
    @media screen and (min-width: 992px) {
      display: flex;
    }
    flex: 0 0 22%;
    align-items: center;
    .airline-logo {
      @media screen and (max-width: 991px) {
        display: block;
        margin: 5px 0;
      }
    }
  }
  .arrive-col,
  .depart-col {
    flex: 0 0 24%;
  }
  .duration-col {
    flex: 0 0 8%;
  }
  .baggage-col {
    flex: 0 0 7%;
    @media screen and (min-width: 992px) {
      text-align: center;
    }
  }
  .airline-logo {
    width: 55px;
    margin-right: 5px;
    object-fit: contain;
  }
}
.itinerary-mobile-pair {
  @media screen and (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    align-items: center;
    padding-bottom: 5px;
    .itinerary-mobile-label + div {
      text-align: right;
    }
  }
}
.flight-item-content {
  border: 1px solid #ddd;
  padding: 10px 10px;
  border-radius: 3px;
  &:hover {
    background-color: #e7f3fd;
  }
  .border-right {
    @media screen and (min-width: 992px) {
      border-right: 1px dotted #ccc;
    }
  }
}
.result-list-label-holder {
  background: #e0e0e0;
  padding: 5px 10px;
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.payment-type-item {
  .bank-icon {
    height: 45px;
    margin-right: 10px;
    max-width: 100px;
    object-fit: contain;
  }
  .payment-method-description {
    margin-top: 10px;
  }
}

.payment-type-item .radio-item {
  padding: 10px 20px;
  background: #f1f1f1;
  border: 1px solid #ddd;
  .radio-label {
    .payment-method-description {
      font-size: 13px;
      margin-bottom: 0;
      padding-left: 20px;
    }
  }
  .radio-mark {
    border-color: #ccc;
  }
  .payment-type-icon {
    height: 35px;
    margin-top: 11px;
  }
  .bank-icon {
    height: 45px;
  }
}
.flight-route-item {
  .resultssection & {
    label {
      @media screen and (max-width: 991px) {
        font-size: 13px;
        margin-right: 15px;
        margin-bottom: 10px;
        display: inline-block;
        min-width: 140px;
      }
    }
  }
  &:not(:first-child) {
    border-top: 1px solid #ccc;
  }
}
.page-heading {
  font-size: 20px;
  margin-top: 20px;
}

.itours-table,
.itours-table table {
  &.ant-table-wrapper {
    border: 0 none;
  }
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  th {
    color: #fff;
    font-weight: normal;
    border: 1px solid;
    background: #565656;
  }
  th,
  td {
    padding: 8px 10px;
    border: 1px solid #ddd;
  }
  tr {
    border-bottom: 1px solid #ddd;
    &:nth-child(even) {
      background-color: #f3f3f3;
    }
  }
}
.table-title {
  background: #565656;
  display: block;
  margin: 0;
  line-height: 25px;
  padding: 7px 10px;
  color: #fff;
  font-size: 17px;
}

//datepicker drop
.date-picker-drop {
  display: flex;
  .ant-select-selection {
    border-radius: 0;
  }
  & > * {
    flex: 1 1;
    &:first-child .ant-select-selection {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:nth-child(3) .ant-select-selection {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}
.ant-calendar-date-panel .ant-calendar-header {
  .ant-calendar-next-month-btn,
  .ant-calendar-prev-month-btn {
    &::before {
      font-size: 25px;
      border-color: #2196f3;
      width: 15px;
      height: 15px;
    }
  }
}
@media screen and (max-width: 767px) {
  .ant-calendar {
    position: fixed !important;
    top: 0 !important;
    left: 0;
    right: 0;
    height: 100vh;
    overflow: auto;
    border-radius: 0;
    z-index: 1;
    width: 100%;
    .ant-calendar-range-middle {
      display: none;
    }
    .ant-calendar-input-wrap {
      display: none;
    }
  }
}

.clear-4n > .ant-col {
  &:nth-child(4n + 1) {
    clear: left;
  }
}
.messsage-card {
  border: 1px solid #ddd;
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
}

.pair-list-item {
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  line-height: 30px;
  align-items: center;
  &:nth-child(odd) {
    background-color: #f1f1f1;
  }
  .airline-logo {
    height: 30px;
  }
}
.inline-block {
  display: inline-block;
  vertical-align: middle;
  &.top-align {
    vertical-align: top;
  }
}

//flight ticketing:
.pnr-status-section {
  background-color: #565656;
  color: #fff;
  z-index: 900;
  .button.refund-btn {
    padding: 5px 15px;
    margin-right: 15px;
  }
}
.flight-ticketing-footer {
  padding: 35px 0;
}

.ticketing-information-list {
  @media screen and (min-width: 1000px) {
    column-count: 3;
  }
  .information-item {
    margin-bottom: 10px;
    line-height: 15px;
    text-transform: lowercase;
  }
}

//responsive styles
@media screen and (max-width: 850px) {
  .main-header .main-menu {
    .ant-menu-item,
    .ant-menu-submenu {
      display: block;
      border: 0 none;
    }
  }
  .SelectRoom .PassengerContainer {
    padding-left: 10px;
    border-left: 5px solid #aaa;
  }
  .ant-menu-submenu.ant-menu-submenu-popup {
    left: 0 !important;
    right: 0;
  }
  .ant-layout-footer {
    padding: 10px 0;
    font-size: 12px;
  }
  .ant-calendar.ant-calendar-range {
    width: 100%;
    .ant-calendar-range-part {
      width: 100%;
    }
  }
}

.flight-search-section {
  .ant-radio-wrapper {
    @media screen and (max-width: 767px) {
      display: block;
      margin-bottom: 5px;
    }
  }
  .set-details-row {
    .ant-col-sm-12 {
      &:nth-child(even) {
        @media screen and (min-width: 576px) and (max-width: 767px) {
          clear: left;
        }
      }
    }
  }
  .flightICON {
    height: 24px;
    margin-right: 10px;
  }
  @media screen and (max-width: 767px) {
    .align-labels {
      margin-bottom: 15px;
      margin-top: 0;
    }
  }
}

//hide classes:
.hidden {
  display: none !important;
}
.hidden-xs {
  @media screen and (max-width: 575px) {
    display: none !important;
  }
}
.hidden-sm {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    display: none !important;
  }
}
.hidden-md {
  @media screen and (min-width: 768px) and (max-width: 991px) {
    display: none !important;
  }
}
.hidden-lg {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    display: none !important;
  }
}
.hidden-xl {
  @media screen and (min-width: 1200px) {
    display: none !important;
  }
}
.SpinPayment {
  background-color: #d9edff;
  text-align: center;
  padding: 20px;
  border: 1px solid $blue;
  .ant-spin-text {
    line-height: 40px;
  }
}

//payment status messages style
.status-box {
  border: 1px solid #ddd;
  border-radius: 4px;
  display: table;
  width: 100%;
  &.processing,
  &.done {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
  }
  &.error {
    background-color: #fff1f0;
    border: 1px solid #ffa39e;
  }
  .icon-box {
    display: table-cell;
    padding: 15px;
    width: 100px;
    text-align: center;
    vertical-align: middle;
    font-size: 50px;
    color: #59c36e;
  }
  .details-box {
    display: table-cell;
    padding: 15px;
    vertical-align: middle;
    .title {
      font-size: 16px;
      margin-bottom: 10px;
      display: block;
    }
    .description {
      font-size: 16px;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}

.showPNRDetails {
  .terminal-id {
    border: 1px solid #aaa;
    padding: 0 3px;
    font-size: 11px;
    margin-left: 4px;
    background: #fff;
    border-radius: 2px;
    white-space: nowrap;
  }
  .airline-logo {
    width: 35px;
  }
  .table-holder {
    overflow: auto;
  }
  .itours-table {
    th,
    td {
      padding: 3px 7px;
      border: 1px solid #ccc;
      text-align: center;
      &.min-width-150 {
        min-width: 150px;
      }
    }
  }
}
.white {
  color: #fff;
}
.ant-calendar-header {
  .ant-calendar-next-year-btn,
  .ant-calendar-prev-year-btn {
    display: none;
  }
  .ant-calendar-next-month-btn {
    right: 15px;
  }
  .ant-calendar-prev-month-btn {
    left: 15px;
  }
}
.bullet-list {
  li {
    list-style-type: none;
  }
}

.small-btn-style {
  font-size: 13px;
  padding: 0 10px;
  line-height: 25px;
  height: auto;
  flex: 1 1;
  margin: 0 3px;
  border-color: #bbb;
  border-left: 3px solid #ff5800;
  color: #000;
  background-color: #eee;
}
.itours-accardion {
  display: none;
  &.visible {
    display: block;
  }
  .itours-accardion-main {
    max-height: 1400px;
    overflow: auto;
  }
  .itours-accardion-inner {
    border: 1px solid #ddd;
    border-top: 0 none;
    border-radius: 0 0 3px 3px;
    .itours-accardion-header {
      border-bottom: 1px solid #ddd;
      padding: 15px;
      font-weight: 600;
    }
    .itours-accardion-main {
      padding: 15px;
    }
    .itours-accardion-footer {
      padding: 10px 15px;
      border-top: 1px solid #ddd;
    }
  }
}
.flight-item-outer {
  .overlay {
    content: '';
    display: none;
    position: fixed;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
  &.visible-details {
    .overlay {
      display: block;
    }
    & > div:not(.overlay) {
      background: #fff;
      position: relative;
      z-index: 11;
      border-radius: 3px;
    }
  }
}
.responsive-table-holder {
  overflow-x: auto;
  .responsive-table {
    min-width: 800px;
  }
}
.itours-modal {
  //display: none;
  visibility: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  &.visible {
    //display: block;
    visibility: visible;
    .modal-inner {
      visibility: visible;
      opacity: 1;
      @media (min-width: 768px) {
        transform: translate(-50%, -50%);
      }
    }
  }

  .modal-overlay {
    background: #0000005e;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .modal-inner {
    position: absolute;
    top: 0;
    left: 0;
    &.max-width-600 {
      max-width: 600px;
    }
    @media (min-width: 768px) {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -60%);
      border-radius: 3px;
    }
    @media (max-width: 767px) {
      height: 100vh;
      overflow-y: auto;
      .modal-main {
        height: calc(100vh - 99px);
        overflow-y: auto;
        max-height: 100vh;
      }
    }
    background: #fff;
    width: 100%;
    max-width: 1100px;
    transition: all 0.2s;
    visibility: hidden;
    opacity: 0;
    &.small-modal-inner {
      max-width: 600px;
    }
  }
  .modal-header {
    border-bottom: 1px solid #bbb;
    padding: 10px 15px;
    font-size: 17px;
  }
  .modal-footer {
    border-top: 1px solid #bbb;
    padding: 10px 15px;
    text-align: right;
  }
  .modal-main {
    padding: 15px;
    max-height: calc(100vh - 140px);
    min-height: 200px;
    overflow: auto;
    .flight-get-passenger & {
      max-height: calc(100vh - 100px);
    }
  }
}
.deposit-card {
  .ant-card-body .gateway-item {
    @media (max-width: 575px) {
      padding: 0;
      border: 0 none;
      &:not(.resurs-bank-item) {
        border-bottom: 1px solid #ccc;
        margin-bottom: 10px;
      }
      .resurs-toggle-btn {
        top: 0;
        right: 0;
      }
    }
  }
  .ant-card-head {
    background: #f1f1f1;
    font-size: 14px;
    color: inherit;
    .description {
      font-size: 13px;
      margin-bottom: 0;
    }
    .deposit-icon {
      height: 35px;
      width: 35px;
      float: right;
    }
  }
}
.loading-min-height {
  min-height: calc(100vh - 212px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.currency-select .ant-select-selection {
  border: 2px solid #555 !important;
  background-color: #f1f1f1;
  transition: all 0.2s linear;
  &:hover {
    background-color: #ccc;
  }
  .ant-select-arrow {
    font-size: 14px;
    svg {
      fill: #555;
    }
  }
}

//select style:
select {
  width: 100%;
  height: 32px;
  padding: 4px 10px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  &:disabled {
    opacity: 0.6;
    background: #d9d9d9;
    cursor: no-drop;
  }
}

//user styles
.show-on-row-hover * {
  opacity: 1;
  visibility: visible;
  transition: all 0.15s linear;
  .ant-table-row:not(.is-expanded):not(:hover) & {
    opacity: 0;
    visibility: hidden;
  }
}
.ant-table-body .ant-table-row-expand-icon {
  width: 80px;
  line-height: 30px;
  padding: 0 15px;
  height: auto;
  border: 1px solid;
  border-radius: 4px;
  &.ant-table-row-collapsed {
    color: #fff;
    background-color: #1890ff;
    border-color: #1890ff;
    &::after {
      .users-expandable-table & {
        content: 'Edit';
      }
      .decrioption-expandable-table & {
        content: 'Details';
      }
    }
  }
  &.ant-table-row-expanded {
    &::after {
      .users-expandable-table & {
        content: 'Cancel';
      }
      .decrioption-expandable-table & {
        content: 'Details';
      }
    }
  }
}
.user-creat-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.table-filter-wrapper {
  display: flex;
  & > div {
    margin-left: 5px;
  }
}
.user-form-box {
  .ant-form-item-with-help span.ant-form-item-children-icon {
    left: auto;
  }
  @media (min-width: 576px) {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 3px;
    label {
      line-height: 30px;
    }
  }
  .user-form {
    @media (min-width: 576px) {
      .form-submit-row {
        max-width: 700px;
        .ant-form-item {
          margin-left: 30%;
        }
      }
      .ant-form-item {
        display: flex;
        align-items: flex-start;
        max-width: 700px;
        &::after,
        &::before {
          content: '';
          display: table;
          clear: both;
        }
      }
      .ant-form-item-label {
        flex: 0 0 30%;
        text-align: right !important;
      }
      .ant-form-item-control-wrapper {
        flex: 0 0 70%;
        max-width: 100% !important;
      }
    }
  }
}
.user-form {
  .ant-row.ant-form-item {
    .ant-col.ant-form-item-label {
      display: block;
      width: 100%;
      text-align: left;
      line-height: 20px;
    }
  }
  .ant-form-item-children-icon {
    left: 100%;
  }
  .ant-form-item-control-wrapper {
    width: 100%;
    //max-width: 285px;
  }
}
.breadcrumpt-section {
  border: 1px solid #ddd;
  padding: 10px 10px;
  margin-bottom: 15px;
  background: #f3f3f3;
}

//commision:
.block-label {
  display: block;
  width: 100%;
  margin-bottom: 5px;
}
.commition-amount span.ant-form-item-children {
  display: flex;
  align-items: center;
  .persent-sign {
    font-size: 16px;
    margin-left: 5px;
    margin-top: 0;
  }
}
.no-padding.ant-btn-link {
  padding: 0;
}

.large-lable .ant-card-head {
  font-size: 20px;
}
.padding-left-20 {
  padding-left: 20px;
}
.padding-right-20 {
  padding-right: 20px;
}
.ant-card-head {
  padding: 0 10px;
}

.flight-get-passenger {
  .selected-flights-info .item-footer {
    @media (max-width: 991px) {
      justify-content: space-between;
      display: flex;
    }
  }
  .flight-footer {
    border: 1px solid $grayMenu;
    border-top: 0;
    background-color: transparent;
    border-radius: 0 0 10px 10px;
    text-align: center;
    &:hover {
      background-color: #f9f9f9;
    }
    button {
      border-radius: 0;
      width: 100%;
      color: gray;
    }
  }
}

.users-list-table {
  overflow-x: auto;
  td {
    border: 1px solid #ddd;
  }
  .action-btns {
    display: flex;
  }
}

.profile-link-holder {
  display: inline-block;
  padding-left: 10px;
  height: 57px;
  vertical-align: middle;
  .profile-link-inner {
    @media (max-width: 575px) {
      text-align: right;
    }
    display: flex;
    align-items: center;
    height: 60px;
    .user-full-name {
      font-size: 16px;
      @media (max-width: 575px) {
        font-size: 13px;
      }
    }
    .deposit {
      @media (max-width: 575px) {
        font-size: 12px;
        .grey {
          font-size: 11px;
        }
      }
    }
  }
}
.account-popover-main {
  width: 280px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  .icon-holder {
    flex: 0 0 30px;
    margin-right: 5px;
    .anticon {
      border: 2px solid;
      font-size: 25px;
      border-radius: 50%;
      padding: 3px;
      margin-right: 8px;
    }
  }
  .name-text {
    font-size: 16px;
    color: #000;
    font-weight: bold;
    line-height: 1.2;
  }
  .links-holder {
    font-size: 13px;
    .devider {
      margin: 0 5px;
    }
  }
  .links-holder .ant-btn-link {
    font-size: 13px;
    padding: 0;
  }
}
.logout {
  padding: 7px 10px;
  background-color: #ff9800;
  color: #fff;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: #ffa31a;
  }
}
.support-wrapper {
  @media (min-width: 991px) {
    display: flex;
    justify-content: center;
  }

  & > div {
    flex: 0 0 20%;
    text-align: center;
    .icon-holder {
      width: 70px;
      height: 70px;
      font-size: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin: 0 auto 15px;
      color: #fff;
      &.address-icon {
        background: #428bca;
      }
      &.call-icon {
        background: #a0bd4a;
      }
      &.mail-color {
        background: #f8b64d;
      }
      &.whatsapp-color {
        background: #4caf50;
      }
      &.telegram-color {
        background: #179cde;
      }
      &.chat-color {
        background-color: #1972f5;
      }
    }
    strong {
      font-size: 18px;
      margin-bottom: 10px;
      margin-top: 15px;
      display: block;
      color: #555;
    }
    p {
      font-size: 12px;
    }
  }
}
.card-title-style {
  border-bottom: 1px solid #ddd;
  padding: 2px 20px 15px;
  font-weight: 500;
  font-size: 16px;
  color: black;
  margin-bottom: 15px;
}
.user-profile-page .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: #fff;
  background-color: #1890ff;
}

.editInformation-form {
  .ant-form-item-label {
    display: block;
    width: 100%;
    text-align: left;
  }
  .ant-form-item-control-wrapper {
    display: block;
    width: 100%;
  }
}
//Home style
.ant-carousel .slick-slide {
  text-align: center;
  height: 200px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
  margin-bottom: 4px;
}
.Notification {
  border: 1px solid $grayMenu;
  height: 242px;
  .header {
    padding: 8px;
    background-color: #ececec;
    font-weight: 500;
    font-size: 16px;
  }
  .container {
    height: 200px;
    padding: 8px;
    overflow-y: auto;
    .newsItem {
      padding: 6px;
      margin-bottom: 4px;
      border-bottom: 1px dotted gray;
      .footerLink {
        text-align: right;
        a {
          cursor: pointer;
        }
      }
    }
  }
}
.promotions-carousel {
  .ant-card-body {
    padding: 0;
    border-top: 1px solid #ddd;
  }
  .slick-slide {
    margin-bottom: 0;
    height: auto;
  }
  // .banner_1{
  //   background-image: url("../Images/design/banner_1.jpg");
  //   height: 300px;
  //   background-size: cover;
  // }
  // .banner_2{
  //   background-image: url("../Images/design/banner_2.jpg");
  //   height: 300px;
  //   background-size: cover;
  // }
}

//airline filter:
.airline-filter-section {
  padding-top: 15px;
}
.airline-filter-wrapper {
  position: relative;
  .right-shadow {
    display: block;
    position: absolute;
    bottom: 22px;
    width: 10px;
    right: 0;
    top: 0;
    background: linear-gradient(90deg, transparent, rgba(0, 0, 0, 0.12));
    opacity: 0;
    transition: all 0.15s linear;
  }
  .left-shadow {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 10px;
    left: 100%;
    top: 0;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.12), transparent);
    opacity: 0;
    transition: all 0.15s linear;
  }
  .airline-filter-label {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    height: 92px;
    padding-right: 5px;
    font-size: 13px;
    text-align: center;
    .airline {
      line-height: 36px;
      white-space: nowrap;
      padding: 0 5px;
    }
    .label {
      line-height: 27px;
      &.gray-bg {
        background-color: #f3f3fb;
      }
      &.blue-bg {
        background-color: #d1e9ff;
      }
    }
  }
  .airline-items-holder {
    display: flex;
    overflow-x: auto;
    padding-bottom: 5px;
    padding-left: 105px;
    margin-bottom: 10px;
  }
  .airline-item {
    margin-right: 5px;
    text-align: center;
    border: 1px solid #ddd;
    border-radius: 3px;
    .airline-logo {
      height: 35px;
      max-width: 75px;
      object-fit: contain;
      display: inline-block;
    }
    .checkbox-holder {
      position: relative;
      white-space: nowrap;
      .custom-checkbox {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        &:not(:checked):hover + .checkbox-label {
          background-color: #deeefd;
        }
        &:checked + .checkbox-label {
          &::before {
            background-image: url('../Images/design/icons.svg');
            //as IE dosnt support svg background:
            /*     @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    background-image: url("../image/icons.png");
            } */
            background-size: 1000% 300%;
            background-position: 44.4% 100%;
            background-color: #1890ff;
          }
        }
      }
      .checkbox-label {
        display: block;
        padding: 0 5px;
        font-size: 13px;
        line-height: 27px;
        min-width: 100px;
        &.gray-bg {
          background-color: #f3f3fb;
        }
        &.blue-bg {
          background-color: #d1e9ff;
        }
        &::before {
          content: '';
          display: inline-block;
          background-color: #fff;
          width: 15px;
          height: 15px;
          vertical-align: middle;
          margin-right: 5px;
          border: 1px solid #ddd;
          border-radius: 2px;
        }
      }
    }
    .no-value {
      line-height: 27px;
      &.gray-bg {
        background-color: #f3f3fb;
      }
      &.blue-bg {
        background-color: #d1e9ff;
      }
    }
  }
}

.simple-bullet-list {
  padding: 0;
  &.no-margin {
    margin-bottom: 0;
  }
  li {
    list-style-position: inside;
    font-size: 12px;
    padding-left: 7px;
    margin-bottom: 5px;
    &.has-list-icon {
      list-style-type: none;
      padding-left: 0;
      .itours-icon,
      .anticon {
        width: 20px;
        height: 20px;
      }
    }
  }
  .cancelation-rules-list {
    padding: 0;
    li {
      font-size: 14px;
      list-style-type: disc;
    }
  }
}

.getPassengers {
  .RoomItem:not(:last-child) {
    border-bottom: 1px solid #ccc;
    margin-bottom: 35px;
    padding-bottom: 25px;
  }
}
.padding-cols {
  & > .ant-col {
    padding: 3px 5px;
  }
}

//new flight item
.hr-dash-line {
  display: block;
  border-bottom: 1px dashed #bbb;
  margin: 10px 0;
}
.org-dur-dst {
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    &:first-child,
    &:last-child {
      flex: 0 0 90px;
    }
  }
}
.baggage-column {
  text-align: center;
  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
}
.flightItem {
  .segment-item-row {
    padding: 5px;
    @media screen and (max-width: 575px) {
      border-bottom: 1px dashed #ddd;
      padding-left: 32px;
      .itinerary-airline-logo {
        position: absolute;
        top: 50%;
        width: 30px;
        left: -27px;
        height: 30px;
        object-fit: contain;
        margin-top: -15px;
      }
      & + .hr-dash-line {
        border: 0 none;
      }
      .dest-of-flight {
        text-align: right;
      }
      .duration-of-flight {
        font-size: 12px;
      }
      .class-airline-info {
        display: flex;
        font-size: 12px;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        margin-bottom: 5px;
        line-height: 1.2;
        margin-left: -5px;
        margin-right: -5px;
        height: 30px;
        & > div {
          padding: 0 5px;
          flex: 1 1;
          &:nth-child(1) {
            text-align: left;
          }
          &:nth-child(2) {
            white-space: nowrap;
            text-align: center;
          }
          &:nth-child(3) {
            white-space: nowrap;
            text-align: right;
          }
        }
      }
    }
  }
  .itinerary-airline-logo {
    max-width: 100%;
    width: 50px;
  }
  .ant-row-flex.padding-cols {
    @media screen and (max-width: 991px) {
      + .ant-row-flex.padding-cols {
        margin-top: 15px;
      }
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    z-index: 2;
  }
  .flight-item-box {
    border: 1px solid #888;
    //padding: 6px;
    margin-bottom: 6px;
    transition: all 0.3s linear;
    .duration-of-flight {
      text-align: center;
      border-top: 2px solid #bbb;
      margin: 0 5px;
      @media screen and (min-width: 991px) {
        padding: 0 10px;
        margin: 0 10px;
      }
    }
    .flight-item-footer {
      @media screen and (min-width: 576px) {
        border-top: 1px solid #ddd;
        padding-left: 7.5%;
        padding-right: 5%;
      }
      padding: 10px;
      & > .ant-row {
        @media screen and (min-width: 576px) and (max-width: 991px) {
          align-items: flex-end;
          display: flex;
        }
        @media screen and (max-width: 991px) {
          .price {
            display: block;
            margin-bottom: 5px;
          }
        }
      }
    }
    .price-buttons-holder {
      @media screen and (max-width: 575px) {
        margin-top: 10px;
      }
      text-align: right;
      .button {
        min-width: 0;
        padding-left: 5px;
        padding-right: 5px;
      }
      .price {
        display: flex;
        flex-direction: column-reverse;
        text-align: right;
        margin-right: 10px;
        color: #111;
        line-height: 1;
        .value {
          font-size: 22px;
          font-weight: bold;
        }
      }
      .align-btns-width {
        width: calc(34% + 90px);
      }
      .align-btns {
        display: flex;
      }
      .book-now-btn {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border: 1px solid #1890ff;
        & + .supplier-color {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border: 1px solid #1890ff;
        }
      }
    }
    .flight-details-btns {
      .flight-details-btns-inner {
        margin-top: -3px;
        margin-bottom: -2px;
        button {
          margin-top: 3px;
          margin-bottom: 2px;
        }
      }
      @media screen and (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 1px;
        .item-footer {
          text-align: right;
        }
      }

      @media screen and (min-width: 992px) {
        .item-footer {
          width: 400px;
          .buttonContainer {
            flex-direction: row-reverse;
            a {
              &.booknow {
                border: 1px dotted $grey;
                border-radius: 6px 0 0 6px;
              }
              &.BookSide {
                border-radius: 0 6px 6px 0;
              }
              max-width: 250px;
            }
          }
          .price {
            font-size: 18px;
          }
        }
      }
      .ant-btn {
        margin-bottom: 5px;
      }
      .item-footer {
        .price {
          font-size: 15px;
        }
      }
    }
  }
  &.visible-details {
    .flight-item-box {
      background-color: #fff;
      position: relative;
      z-index: 2;
      border-radius: 4px;
    }
    .itours-accardion {
      background: #fff;
      position: relative;
      z-index: 2;
      border-radius: 4px;
    }
  }
  hr {
    border: 1px solid $grayMenu;
  }
}
.padding-5 {
  padding: 5px;
}

.padding-10 {
  padding: 10px;
}

.padding-15 {
  padding: 15px;
}

.padding-20 {
  padding: 20px;
}

.border-bottom {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.old-price {
  color: rgb(119, 119, 119);
  font-size: 14px;
  font-weight: 600;
  text-decoration: line-through;
}
.formCommission {
  background-color: #e0e0e0;
  padding: 8px;
  border-radius: 4px;
  .isPercentage {
    padding-top: 32px;
  }
}
.phone-number-validation-message {
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: all 0.3s linear;
  color: #f5222d;
  &.show {
    bottom: -5px;
    opacity: 1;
  }
}
.intl-tel-input {
  width: 100%;
  .form-control {
    height: 32px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding-top: 4px;
    padding-bottom: 4px;
    transition: all 0.3s;
    outline: 0 none;
    width: 100%;
    &:hover {
      border-color: #40a9ff;
    }
    &:focus {
      border-color: #40a9ff;
      box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
    }
    .ant-form-item.error & {
      border-color: #f5222d;
      &:hover {
        border-color: #f5222d;
      }
      &:focus {
        border-color: #f5222d;
        box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
      }
    }
    .ant-form-item-control.has-error & {
      border-color: #f5222d;
    }
    &[disabled] {
      background-color: whitesmoke;
      color: #bbb;
      cursor: no-drop;
      font-weight: normal;
      & ~ .flag-container {
        opacity: 0.1;
      }
    }
  }
}
.user-form {
  .ant-calendar-picker {
    width: 100%;
    display: block;
  }
  .ant-row.ant-form-item.amount-currency {
    margin-bottom: 0;
    margin-left: 10px;
    min-width: 110px;
    display: block;
  }
}
.ant-form-item-children {
  display: flex;
}
.result-process-loading {
  color: #ffc107;
  font-size: 55px;
  margin-bottom: 20px;
  height: 55px;
  overflow: hidden;
  i.anticon.anticon-loading {
    vertical-align: top;
  }
}

.ant-modal-root {
  position: relative;
  z-index: 10000;
}
.buttonContainer {
  display: flex;
}

//flight summary style in choice payment page:
.choose-payment-sidebar {
  @media screen and (min-width: 768px) {
    margin-top: 32px;
  }
}
.sidebar-flight-summary .ant-card-body {
  padding: 10px;
  .itinerary-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .airline-logo {
      width: 40px;
    }
    .origin {
      text-align: left;
    }
    .destination {
      text-align: right;
    }
    .stops {
      font-size: 12px;
    }
  }
  .ant-collapse-header {
    padding: 0 0 0 20px;
    color: $blue;
    .ant-collapse-arrow {
      left: 2px !important;
      margin-top: 0px;
    }
    .pair-row {
      margin-bottom: 0;
    }
  }
  .ant-collapse-content-box {
    padding: 0;
    .pair-row {
      margin-bottom: 0;
    }
  }
  .ant-collapse-item {
    border-bottom: 0 none !important;
    margin-bottom: 5px;
    font-size: 13px;
  }
  .prices-info {
    border-bottom: 1px solid #ddd;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }
  .card-footer {
    align-items: center;
    flex-wrap: wrap;
  }
}
.hr-divider {
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 1px 0;
}
.clear-left-from-sm {
  @media screen and (min-width: 576px) and (max-width: 767px) {
    clear: left;
  }
  .home-search-wrapper & {
    @media screen and (min-width: 992px) {
      clear: left;
    }
  }
}
.form-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}
.home-flight-search {
  .set-details-row {
    .ant-col {
      @media screen and (min-width: 992px) {
        &:nth-child(5) {
          clear: left;
        }
      }
    }
  }
}
.reserve-list-filter-card {
  label.filter-reserves {
    margin-bottom: 5px;
  }
}

//hide empty when table data is loading:
.ant-spin-container .ant-empty {
  opacity: 0;
  .transaction-report-page & {
    opacity: 1;
  }
}

//deposit style:
.tab-extra-block-xs .ant-tabs-extra-content {
  @media screen and (max-width: 374px) {
    float: none !important;
  }
}

.ant-table-content .ant-table-body .ant-table-tbody .ant-table-row.light-red-bg td {
  background-color: rgba(244, 54, 107, 0.1);
}
.deposit-page {
  .padding-10 {
    padding: 10px !important;
  }
  .gateway-item {
    b,
    p {
      margin-bottom: 0;
    }
  }
  .hint-image {
    max-width: 100%;
    display: block;
    margin: 20px auto;
  }
  .deposit-lists {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
    .ant-statistic {
      background-color: #effbec;
      background: linear-gradient(90deg, #48e3d6, #119cd4);
      &:nth-child(2) {
        background: linear-gradient(90deg, #9171fe, #fd5e9f);
      }
      &:nth-child(3) {
        background: linear-gradient(90deg, #f8d18c, #f57772);
      }
      &:nth-child(4) {
        background: linear-gradient(90deg, #48e3d6, #f8d18c);
      }
      &:nth-child(5) {
        background: linear-gradient(90deg, #80bdec, #3f51b5);
      }
      &:nth-child(6) {
        background: linear-gradient(90deg, #48e3d6, #119cd4);
      }
      &:nth-child(7) {
        background: linear-gradient(90deg, #48e3d6, #119cd4);
      }
      padding: 15px;
      flex: 1 1 31%;
      border-radius: 3px;
      position: relative;
      margin: 5px;
      .ant-statistic-content,
      .ant-statistic-title {
        position: relative;
        z-index: 1;
        color: #fff;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      }
      .ant-statistic-title {
        display: inline-block;
        background: rgba(255, 255, 255, 0.4);
        border-radius: 50%;
        height: 40px;
        width: 40px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 0;
      }
      &:before {
        content: '';
        display: block;
        background-image: url(../Images/design/circuit.svg);
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
        opacity: 0.5;
      }
    }
  }
  .deposit-card {
    border: 0 none;
    .ant-card-head {
      display: none;
    }
    .ant-card-body {
      padding: 0;
    }
  }

  .charge-deposit-col {
    @media screen and (min-width: 768px) {
      float: right;
    }
  }

  .credit-list.green {
    margin: 0;
    padding: 0;
    list-style-position: inside;
  }
}
.bank-account-icon {
  height: 25px;
  margin-right: 5px;
  margin-top: -3px;
  font-size: 10px;
  color: #aaa;
}
.ant-table-body {
  overflow: auto;
}
tbody.ant-table-tbody,
.ant-form-item-control {
  .ant-skeleton-content {
    .ant-skeleton-paragraph {
      margin: 0;
    }
    .ant-skeleton-title {
      margin: 0;
      width: 100%;
    }
  }
}
//skleton styles
.one-line-skleton {
  .ant-skeleton-paragraph {
    margin: 0 !important;
  }
}
.form-group-skeleton {
  .ant-skeleton-content {
    .ant-skeleton-paragraph {
      margin: 0;
    }
    .ant-skeleton-title {
      margin: 10px 0 5px;
    }
  }
  &.full-width-li {
    .ant-skeleton-paragraph li {
      width: 100% !important;
    }
  }
}
.no-title-skeleton .ant-skeleton-content {
  .ant-skeleton-title {
    display: none;
  }
  .ant-skeleton-paragraph {
    margin: 0;
  }
}
.ant-card-head-title .ant-skeleton-title {
  margin: 5px 0;
}
.ant-skeleton.center-align-avatar .ant-skeleton-header {
  vertical-align: middle;
}
.right-aligned-skleton {
  .ant-skeleton-content {
    text-align: right;
    .ant-skeleton-title {
      display: inline-block;
    }
  }
  .ant-skeleton-paragraph {
    margin-top: 0 !important;
    li {
      float: right;
      clear: both;
    }
  }
}
.steps-skeleton {
  margin-top: 10px;
  margin-bottom: 27px;
}
.loading-images.map-loading-image .image {
  height: 350px;
  background-image: url('../Images/design/map-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
.loading-images .image {
  position: relative;
  background-color: #ddd;
  background-image: url('../Images/design/gallery.svg');
  background-size: 150px;
  overflow: hidden;
  background-repeat: repeat;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 200%;
    height: 100%;
    width: 200%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    animation: imageLoadingAnimation 1.5s linear 1s infinite;
  }
}

@keyframes imageLoadingAnimation {
  from {
    right: 200%;
  }
  to {
    right: -200%;
  }
}

.leaflet-container {
  z-index: 1;
}

.small-empty-box .ant-card-body {
  padding: 10px 0 15px;
  .ant-empty-description {
    margin: 0;
  }
}
.has-error select {
  border-color: #f5222d;
}

.clear-left-sm {
  @media (min-width: 567px) and (max-width: 767px) {
    clear: left;
  }
}
.clear-left-md {
  @media (min-width: 768px) and (max-width: 991px) {
    clear: left;
  }
}
.clear-left-lg {
  @media (min-width: 992px) and (max-width: 1199px) {
    clear: left;
  }
}
.clear-left-xl {
  @media (min-width: 1200px) {
    clear: left;
  }
}
.large-price-holder .price {
  font-size: 20px;
  font-weight: 600;
}
.align-radio-label {
  padding-left: 25px;
}

.ant-modal.refund-modal .ant-modal-body {
  padding: 0;
  .modal-paddidng {
    padding: 24px;
  }
  .refund-modal-footer {
    border-top: 1px solid #e8e8e8;
    padding: 10px 24px;
  }
  .result-loading {
    color: #52c41a;
    font-size: 50px;
  }
  .ant-result {
    padding-top: 20px;
    padding-bottom: 30px;
  }
}
.verification-status {
  font-size: 13px;
  white-space: nowrap;
  position: absolute;
  top: 10px;
  right: 8px;
}
.verification-hint {
  line-height: 17px;
  font-size: 13px;
  button.ant-btn.ant-btn-link {
    height: auto;
    line-height: inherit;
    padding: 0 5px;
    font-size: inherit;
  }
}
.remove-flight-btn {
  margin-top: 20px !important;
}
.captha-image-holder {
  background-image: url("../Images/design/captha-bg.jpg");
  text-align: center;
  .captha-image {
    height: 32px;
  }
}
.s-datepicker-holder  {
  .DatePicker {
    width: 100%;
    z-index: 3;
    .DatePicker__input{
      width: 100%;
      outline: 0 none;
      text-align: left;
      font-size: inherit;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
        height: 32px;
      &:hover{
        border-color: #40a9ff;
        
      }
    }
  }
  .datepicker-toggle-btn {
    position: absolute;
    right: 1px;
    top: 1px;
    z-index: 4;
    height: 30px;
    background: #dadada;
    border: 0 none;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    outline: none;
}
}
.font-fa {
  font-family: 'vazir';
}
.left-placeholder{
  &::-webkit-input-placeholder { /* Edge */
    text-align: left;
    font-family: 'Open Sans';
    padding-top: 6px;
  }
  
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    text-align: left;
    font-family: 'Open Sans';
    padding-top: 6px;
  }
  
  &::placeholder {
    text-align: left;
    font-family: 'Open Sans';
    padding-top: 6px;
  }
}
.timeOut-modal .timer-large-icon{
  font-size: 35px;
  color: #ccc;
}

.flight-summary-tags-holder {
  border: 1px solid #dad6d6;
  border-top: 0 none;
  span.ant-tag {
    margin-bottom: 0;
    padding-top: 2px;
    padding-bottom: 3px;
  }
}