.SelectRoom {
  @media screen and (min-width: 500px) {
    display: flex;
    .Slide {
      padding: 0 2px;
      &.Age {
        max-width: 98px;
      }
      .PassengerContainer {
        display: flex;
        .home-search-wrapper & {
          flex-wrap: wrap;
          .Slide {
            flex: 1 0 60px;
            select {
              padding: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 850px) {
  .Age .ant-select-selection__rendered {
    margin-right: 10px;
    margin-left: 3px;
    font-size: 12px;
    .ant-select-arrow {
      right: 3px;
    }
  }
}
