/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../Fonts/opensans/open-sans-v17-latin-600.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../Fonts/opensans/open-sans-v17-latin-700.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}

@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../Fonts/vazir/Vazir-FD.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}
@font-face {
  font-family: 'vazir';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../Fonts/vazir/Vazir-Bold-FD.woff2') format('woff2') /* Chrome 26+, Opera 23+, Firefox 39+ */
}


//var
$navbarHeight: 60px;
//colors
$grayMenu: #dad6d6;
$grayMenuHov: #b9b9b9;
$blue: #1890ff;
$blueHover: #0077e6;
$darkblue: #003580;
$red: #f5222d;
$green: #308c6a;
$yellow: #ff9800;
$violet: #673ab7;
$grey: #aaa;
$orange: orange;
